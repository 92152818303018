import firebase from 'firebase/app';
import 'firebase/auth';

const baseUrl = "https://aztest2.rationalagents.com";

const userEmail = () =>
    firebase.auth().currentUser.email;

const upload = (file) => {
    var url = new URL(baseUrl)
        .toString();

    let formData = new FormData();
    formData.append("key", firebase.auth().currentUser.email)
    formData.append("file", file);

    return fetch(url, {
        method: 'POST',
        body: formData
    });

    // Response will be text indicating details about the upload (success or fail)
};

const getSession = (id) => {
    var url = new URL(baseUrl + "/session");
    url.search = new URLSearchParams({
        key: userEmail(),
        id: id,
    }).toString();

    return fetch(url);

    // Response is map of key -> session (but there's only one session included)
};

const getSessions = () => {
    var url = new URL(baseUrl + "/sessions");
    url.search = new URLSearchParams({
        key: userEmail(),
    }).toString();

    return fetch(url);

    // Response is map of key -> session
};

const createSession = (name, method, startsWith) => {
    var url = new URL(baseUrl + "/session/create");

    var params = {
        key: userEmail(),
        name: name,
        method: method,
    };

    if (method === 'AZ' && startsWith !== undefined)
        params.startsWith = startsWith;

    url.search = new URLSearchParams(params)
        .toString();

    return fetch(url, {
        method: 'POST'
    });

    // Response is single session
};

const deleteSession = (id) => {
    var url = new URL(baseUrl + "/session/delete");
    url.search = new URLSearchParams({
        key: userEmail(),
        id: id,
    }).toString();

    return fetch(url, {
        method: 'POST'
    });
};

const updateSession = (session) => {
    var url = new URL(baseUrl + "/session/update");
    url.search = new URLSearchParams({
        key: userEmail()
    }).toString();

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(session),
    });
};

const exports = {
    upload,
    getSession,
    getSessions,
    createSession,
    deleteSession,
    updateSession,
}

export default exports;