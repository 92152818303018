import React, { useState, useRef } from "react";
import { Button, Snackbar } from "@material-ui/core";
import Backend from "./Backend";

const Upload = () => {

    const [currentFile, setCurrentFile] = useState(undefined);
    const [message, setMessage] = useState("");
    const [messageOpen, setMessageOpen] = useState(false);
    const inputRef = useRef(null);

    const clickButton = (e) => {
        e.preventDefault();
        inputRef.current.click();
    }

    const closeSnackbar = () => {
        setMessageOpen(false);
    }

    const upload = (e) => {
        let currentFile = e.target.files[0];
        setCurrentFile(currentFile);

        Backend.upload(currentFile)
            .then(response => response.text())
            .then(message => {
                setMessage(message);
                setMessageOpen(true);
                setCurrentFile(undefined);
                inputRef.current.value = "";
            });
    };

    return (
        <div>
            <input ref={inputRef} type="file" accept=".zip" style={{ display: "none" }} onChange={upload} />
            <Button variant="contained" onClick={clickButton} disabled={currentFile !== undefined}>
                Update friends
            </Button>
            <Snackbar open={messageOpen} autoHideDuration={6000} onClose={closeSnackbar} message={message} />
        </div>
    );
};

export default Upload;