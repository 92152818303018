import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import { Box, Grid, Typography, Button, Switch, FormControlLabel, CircularProgress } from "@material-ui/core";
import Backend from "./Backend";

const PlaySession = () => {

    const { id } = useParams();
    const [session, setSession] = useState(undefined);
    const [isMobile, setIsMobile] = useState(true);
    const history = useHistory();

    useEffect(() => {
        Backend.getSession(id)
            .then(response => response.json())
            .then(data => {
                const sess = data[id];

                // TODO: real error case (can simulate w/ bad id)
                if (!sess) return;

                // start it if not already started
                if (sess.index < 0) sess.index = 0;

                setSession(sess);
            });
    }, [id]);

    const clickVisit = () => {
        const baseUrl = isMobile
            ? "fb://facewebmodal/f?href=https://facebook.com/search/people%3fq"
            : "https://facebook.com/search/people?q"

        // TODO: I guess for the mobile URL, we need to double-encode it. Would be easier to just set the entire URL as the value of the QS param, but I didn't test perfect encoding w/ Facebook mobile app
        const friendNameQueryParams = new URLSearchParams({
            q: calcCurrentFriend(),
        }).toString();
        const justEncodedFriendName = friendNameQueryParams.slice(1, friendNameQueryParams.length);

        window.open(baseUrl + justEncodedFriendName, "_blank", "noopener,noreferrer");
    };

    const clickNext = () => {
        let sess = { ...session, ...{ index: session.index + 1 } };
        setSession(sess);
        Backend.updateSession(sess);
    };

    const clickPrev = () => {
        let sess = { ...session, ...{ index: session.index - 1 } };
        setSession(sess);
        Backend.updateSession(sess);
    };

    const clickEnd = () => {
        history.push("/");
        // TODO: get out of here better, but what about if we loaded by URL?
    };

    const calcNum = () => {
        return session.index + 1;
    }

    const calcDenom = () => {
        return session.orderedFriends?.length;
    }

    const calcProgress = () => {
        let num = calcNum();
        let denom = calcDenom();
        if (num > denom) return "Done";
        return calcNum() + " of " + calcDenom();
    }

    const calcCurrentFriend = () => {
        return session.orderedFriends ? session.orderedFriends[session.index] : "...";
    }

    const calcNextDisabled = () => {
        return session.index >= session.orderedFriends?.length - 1;
    }

    const calcPrevDisabled = () => {
        return session.index <= 0;
    }

    const calcVisitDisabled = () => {
        return session.index < 0 || session.index >= session.orderedFriends?.length;
    }

    const calcFriendyMethod = () => {
        switch (session.method) {
            case "AZ":
                return "By first name";
            case "Shuffle":
                return "Shuffled";
            case "OldestFirst":
                return "By oldest friendship"
            default:
                return session.method;
        }
    }

    return (
        <Box>
            {!session && <CircularProgress/>}
            {session && <Grid container direction="column" spacing={1}>
                <Grid item xs >
                    <Typography variant="h6">{session.name}</Typography>
                    <Typography>{calcFriendyMethod()}</Typography>
                    <Typography variant="h4">{calcCurrentFriend()}</Typography>                    
                    <Typography> {calcProgress()}</Typography>
                </Grid>
                <Grid item >
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="contained" onClick={e => clickVisit()} disabled={calcVisitDisabled()}>Visit Profile</Button>                                               
                        </Grid>
                        <Grid item>
                        <Button variant="contained" onClick={e => clickNext()} disabled={calcNextDisabled()}>Next</Button>                                      
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={e => clickPrev()} disabled={calcPrevDisabled()}>Previous</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item >
                    <FormControlLabel
                        control={<Switch checked={isMobile} onChange={e => setIsMobile(e.target.checked)} />}
                        label="Use mobile app"
                    />
                </Grid>
                <Grid item >
                    <Button variant="contained" onClick={e => clickEnd()}>Save and Quit</Button>
                </Grid>
            </Grid>}
        </Box>
    );
};

export default PlaySession;