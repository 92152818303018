import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
    root: {
        margin: "0 0 1em 0"
    },
});

const Session = ({session}) => {

    const history = useHistory();
    const classes = useStyles();

    const calcFriendyMethod = () => {
        switch (session.method) {
            case "AZ":
                return "By first name" + (session.methodModifier ? " starting with \"" + session.methodModifier + "\"": "");
            case "Shuffle":
                return "Shuffled";
            case "OldestFirst":
                return "By oldest friendship"
            default:
                return session.method;
        }
    }

    const playClick = (id) => {
        history.push('/playsession/' + id);
    };

    const deleteClick = (id) => {
        alert("Can't delete yet, sorry");
        // TODO: implement delete at server
    };

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant="h6">
                    {session.name}
                </Typography>
                <Typography>
                    { calcFriendyMethod() }
                </Typography>                    
            </CardContent>
            <CardActions>
                <Button variant="contained" onClick={(e) => playClick(session.id)}>
                    {session.index < 0 && <span>Start</span>}
                    {session.index >=0 && <span>Resume</span>}            
                </Button>
                <Button variant="contained" onClick={(e) => deleteClick(session.id)}>Delete</Button>
            </CardActions>
        </Card>
    );

};

export default Session;