import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, FormHelperText, Select, InputLabel, MenuItem, TextField, Button, FormControl, CardActions, Grid } from "@material-ui/core";
// import { display } from '@material-ui/system';
import Backend from "./Backend";

const CreateSession = () => {

    const [name, setName] = useState("");
    const [startsWith, setStartsWith] = useState(undefined);
    const [method, setMethod] = useState("Shuffle");
    const history = useHistory();

    const clickCreate = () => {
        Backend.createSession(name, method, startsWith)
            .then(response => response.json())
            .then(data => {
                // const newEntry = {};
                // newEntry[data.id] = data;
                //setSessions({...newEntry, ...sessions});
                history.goBack();
            });
    };

    const clickCancel = () => {
        history.goBack();
    };

    const displayStartsWith = () => {
        return method === 'AZ' ? "block" : "none";
    }

    return (
        <Grid container direction="column" spacing={3}>

            <Grid item>
                <FormControl>
                    <InputLabel id="methodLabel">Friend&nbsp;order</InputLabel>
                    <Select labelId="methodLabel" value={method} onChange={e => setMethod(e.target.value)}>
                        <MenuItem value="Shuffle">shuffled</MenuItem>
                        <MenuItem value="AZ">by first name (A-Z)</MenuItem>
                        <MenuItem value="OldestFirst">by oldest friendship</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Box component={Grid} item display={displayStartsWith()}>            
                    <TextField label=" starting with" variant="filled" value={startsWith} onChange={e => setStartsWith(e.target.value)} />                
                    <FormHelperText>If you want to start at a specific first name, rather than at the <i>A</i>s, type that above.</FormHelperText>            
            </Box>

            <Grid item>
                <TextField label="Session name" variant="filled" value={name} onChange={e => setName(e.target.value)} />
                <FormHelperText>For example, "July 2021 A to Z". Choose a name that helps you distinguish between sessions, so this app can help you see what you've done over time.</FormHelperText>
            </Grid>

            <Grid item>
                <CardActions>
                    <Button variant="contained" onClick={clickCreate} disabled={name.length === 0}>Create</Button>
                    <Button variant="contained" onClick={clickCancel}>Cancel</Button>
                </CardActions>
            </Grid>
        </Grid>
    );
};

export default CreateSession;