import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Button, CircularProgress, Grid } from "@material-ui/core";
import Upload from "./Upload";
import Session from "./Session";
import Backend from "./Backend";

const Home = () => {

    const [sessions, setSessions] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        Backend.getSessions()
            .then(response => response.json())
            .then(data => {
                setSessions(data);
                setIsLoading(false);
            });
    }, []);

    const clickHelp = () => {
        window.location.href = process.env.REACT_APP_SUPPORT_URL;
    };

    const clickCreateSession = () => {
        history.push("/createsession");
    };

    const getInDescCreateOrder = () => {
        let results = [];
        for (const key in sessions) {
            results.push(sessions[key]);
        }
        return results.sort((l, r) => r.created - l.created);
    };

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item>
                    <Upload />
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={clickCreateSession}>New session</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={clickHelp}>Help</Button>
                </Grid>
                <Grid item>
                    <Button onClick={() => firebase.auth().signOut()} variant="contained">Sign out</Button>
                </Grid>
            </Grid>
            <div style={{'margin': '1em 0 0 0'}}>
            {getInDescCreateOrder().map(session =>
                    <Session key={session.id} session={session} />
            )}
            </div>
            {isLoading && <CircularProgress style={{'margin': '1em'}}/>}
        </div>
    );

};

export default Home;