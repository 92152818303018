import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Box, CircularProgress } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Home from './Home';
import CreateSession from './CreateSession';
import PlaySession from './PlaySession';

// Configure Firebase.
const config = {
  apiKey: 'AIzaSyB8ClgoDdzuL_Cwcw8IlXdHG7b1jG67pnQ',
  authDomain: 'localhost:3000',
};

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in
    signInSuccessWithAuthResult: () => false,
  },
};


if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  // hot-reload
  firebase.app();
}

function App() {
  const [isInitSignIn, setIsInitSignIn] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: 'light',
        },
      }),
    [],
  );

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsInitSignIn(false);
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when component unmounts
  }, []);

  const getInterior = () => {
    if (isInitSignIn) {
      return (
        <CircularProgress />
      );
    }
    else if (!isSignedIn) {
      return (
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      );
    } else {
      return (
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/createsession" component={CreateSession} />
            <Route path="/playsession/:id" component={PlaySession} />
          </Switch>
        </BrowserRouter>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box m={3}> {
        getInterior()
      }
      </Box>
    </ThemeProvider>
  );
}

export default App;
